/**
 * This file contains the global JavaScript code that is shared across all Heyflow funnels.
 */
window.Client = {
    init: function() {
        startpageRedirections();
        singleSelectionCheckboxes();
    },

    onRender: function(screenId) {
        // Do something when the flow renders
    }
}

/**
 * Sets up redirection logic for radio buttons based on the currently mounted funnel.
 * If a different funnel is selected, it redirects to the appropriate URL.
 * If already on the correct funnel, it navigates to the next screen.
 */
function startpageRedirections() {

    const radioButtons = document.querySelectorAll('[name="start"] .multiple-choice-option-container input[type="radio"]');
    const currentlyMounting = window.currentlyMounting; // Get the dynamic value

    // Mapping of indexes to their corresponding funnel names and start URLs
    const funnelMapping = [
        { name: 'jacasa-flat-valuation', url: 'https://wohnungsbewertung.jacasa.de/#wohnflaeche' },
        { name: 'jacasa-house-valuation', url: 'https://hausbewertung.jacasa.de/#wohnflaeche' },
        { name: 'jacasa-commercial-valuation', url: 'https://gewerbebewertung.jacasa.de/#gebaeudeart' },
        { name: 'jacasa-land-valuation', url: 'https://grundstuecksbewertung.jacasa.de/#grundstuecksflaeche' }
    ];

    radioButtons.forEach((radioButton, index) => {
        radioButton.addEventListener('click', function() {
            const funnel = funnelMapping[index];

            if (funnel && currentlyMounting !== funnel.name) {
                // Redirect to the appropriate funnel URL if it's not currently mounted
                window.location.href = funnel.url;
            } else if (typeof window.heyflow !== 'undefined' && window.heyflow[currentlyMounting]) {
                // Navigate to the next screen if already on the correct funnel
                window.heyflow[currentlyMounting].navigation.navigate('next', '');
            }
        });
    });
}

/**
 * Ensures that only one checkbox within a specified section can be selected at a time.
 * When one checkbox is selected, the others are automatically deselected.
 */
function singleSelectionCheckboxes() {
    const section = document.querySelector(`section[name="vorname-nachname"]`);

    if (section) {
        const checkboxes = section.querySelectorAll('input[type="checkbox"]');
        const maleCheckbox = [...checkboxes].find(cb => cb.value === 'mr');
        const femaleCheckbox = [...checkboxes].find(cb => cb.value === 'ms');

        if (maleCheckbox && femaleCheckbox) {
            maleCheckbox.checked = true;

            checkboxes.forEach(checkbox => {
                checkbox.addEventListener('change', () => {
                    if (checkbox.checked) {
                        checkboxes.forEach(cb => {
                            if (cb !== checkbox) cb.checked = false;
                        });
                    }
                });
            });
        }
    }
}
